<template lang="pug">

.q-px-xl(style="padding-top:80px;padding-bottom:80px;")
  //- description
  .flex.flex-center.column
    .q-px-xl.flex.flex-center.column(style="max-width:600px;")
      .row.text-h4.text-bold
        q-icon(name="lens_blur" left)
        | Activity Assistant
      .q-ma-md.text-subtitle1.text-center
        | You can test right now with your own video clips.
        br
        | It is free. No signup required.
  //- slider
  .q-px-xl.q-mt-lg.flex.flex-center.column
    q-slider(
      v-model="sensitivity"
      label-always
      :label-value="`Sensitivity: ${sensitivity}`"
      :min="5" :max="100"
      track-size="8px"
      style="width:700px;max-width:calc(100% - 60px);")
    c-control-102(
      :playing="playing"
      @blob="blob=$event"
      @rewind="rewind=$event"
      @zoom="zoom=$event"
      @annotate="annotate=$event"
      @toggle-play="togglePlay=$event"
      style="max-width:calc(100% - 60px);")
  //- preview
  .q-px-xl.q-mt-lg.flex.flex-center
    .bg-white.rounded-borders.shadow-10.no-scroll.relative-position(
      style="width:700px;height:480px;")
      q-uploader(
        ref="activity-assistant-uploader"
        :filter="checkFileType"
        @added="onFileAdded"
        style="width:100%;min-height:100%;"
        color="grey"
        max-files="10"
        multiple
        flat)
        template(v-slot:header="scope")
        template(v-slot:list="scope")
          template(v-if="selectedFile")
            //- video player
            c-video-102(
              @playing="playing=$event"
              :blob="blob"
              :rewind="rewind"
              :zoom="zoom"
              :annotate="annotate"
              :togglePlay="togglePlay"
              :sensitivity="sensitivity"
              :file="selectedFile")
          template(v-else)
            .flex.column.flex-center(style="width:100%;height:100%;position:absolute;top:0;left:0;")
              .text-h5.text-capitalize.q-mb-md Drag and drop
              .text-caption Add a video file to test, only mp4 format supported.

</template>

<style>
</style>

<script>
export default {
  data: function () {
    return {
      selectedFile: null,
      sensitivity: 90,
      // Video
      playing: false,
      // Control
      blob: 1,
      rewind: false,
      zoom: false,
      annotate: false,
      togglePlay: 0,
    }
  },
  methods: {
    selectFile: function (file) {
      this.selectedFile = file
    },
    resetUploader: function () {
      if (!(this.$refs['activity-assistant-uploader'] instanceof Object)) return
      this.$refs['activity-assistant-uploader'].reset()
    },
    onFileAdded: function (files) {
      this.selectFile(files[0])
      this.resetUploader()
    },
    checkFileType: function (files) {
      const uList = []
      for (let i=0; i < files.length; i++) {
        const file = files[i]
        // CHECK - file type (only mp4 file)
        switch (file.type) {
        case 'video/mp4':
          break
        default:
          this.onFileRejected(file, 'is not mp4 video')
          continue
        }
        // CHECK - file size (less than 10MB)
        if (file.size > 1024*1024*10) {
          this.onFileRejected(file, `is larger than 10MB: ${file.size} bytes`)
          continue
        }
        uList.push(file)
      }
      return uList
    },
    onFileRejected: function (file, reason) {
      this.$q.notify({
        type: 'warning',
        message: `"${file.name}" ${reason}`
      })
    },
  },
}
</script>
