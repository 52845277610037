<template lang="pug">

q-header.bg-grey-2.flex.flex-center
  q-toolbar.q-pa-xl.bg-grey-2.text-grey-8(style="max-width:1000px;")
    .text-subtitle1.row.flex-center
      q-icon(name="psychology" size="md" color="blue").q-mr-xs
      span.text-black Video AI
      span.text-grey-6 &nbsp;for central stations
    q-space
    q-btn(flat no-caps :ripple="false" @click="$tool.scrollToId('tracker')") Tracker
    q-btn(flat no-caps :ripple="false" @click="$tool.scrollToId('assistant')") Assistant
    q-btn(flat no-caps :ripple="false" to="/lab") Lab

</template>

<style>
</style>

<script>
export default {
}
</script>
