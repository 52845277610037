<template lang="pug">

.q-px-xl(style="padding-top:80px;padding-bottom:80px;")
  //- description
  .flex.flex-center.column
    .q-px-xl.flex.flex-center.column(style="max-width:600px;")
      .row.flex-center
        q-icon(
          size="80px"
          name="img:/img/person-detected.png"
          left)
        .text-h4.text-bold Object Tracker
      .q-ma-md.text-subtitle1.text-center
        | You can test right now with your own video clips.
        br
        | It is free. No signup required.
    //- pills
    .q-pa-lg.row.flex-center(style="gap:8px;")
      q-btn(
        unelevated rounded no-caps
        :ripple="false"
        :text-color="selected === 'vca' ? 'white' : 'grey-6'"
        :color="selected === 'vca' ? 'black' : null"
        @click="selected='vca'")
        img(src="/img/vca-logo.png" style="height:20px;")
        .q-pl-sm VCA Technology
      q-btn(
        label="Calipsa"
        unelevated rounded no-caps
        icon="img:/img/calipsa-logo.png"
        :ripple="false"
        :text-color="selected === 'calipsa' ? 'white' : 'grey-6'"
        :color="selected === 'calipsa' ? 'black' : null"
        @click="selected='calipsa'")
      //- q-btn(
        label="GCP"
        disabled
        unelevated rounded no-caps
        :icon="$icon.ionLogoGoogle"
        :ripple="false"
        :text-color="selected === 'gcp' ? 'white' : 'grey-6'"
        :color="selected === 'gcp' ? 'black' : null"
        @click="selected='gcp'")
  //- preview
  .q-px-xl.q-mt-lg.flex.flex-center
    .bg-white.rounded-borders.shadow-10.no-scroll.relative-position(
      style="width:700px;height:480px;")
      q-uploader(
        ref="object-tracker-uploader"
        :filter="checkFileType"
        @added="onFileAdded"
        style="width:100%;min-height:100%;"
        color="grey"
        max-files="10"
        multiple
        flat)
        template(v-slot:header="scope")
          template(v-if="files.length > 0")
            q-toolbar
              q-toggle(
                v-model="toggle"
                checked-icon="ondemand_video"
                color="black"
                unchecked-icon="dvr")
              q-space
              q-btn(
                @click="onClickClearAll"
                size="md"
                no-caps color="black" icon="clear" label="Clear All")

        template(v-slot:list="scope")
          template(v-if="files.length > 0")
            template(v-if="toggle")
              //- video player
              c-video-101.full-width.full-height(
                style="position:absolute;top:0;left:0;"
                annotate
                :file="selectedFile" :apiName="selected")
            template(v-else)
              //- file list table
              c-table-101(
                style="position:absolute;top:0;left:0;width:100%;height:100%;"
                @click-file="onClickFile"
                @delete-file="onDeleteFile"
                :files="files")
          template(v-else)
            .flex.column.flex-center(style="width:100%;height:100%;position:absolute;top:0;left:0;")
              .text-h5.text-capitalize.q-mb-md Drag and drop
              .text-caption Add video file(s) to test, only mp4 format supported.

</template>

<style>
</style>

<script>
export default {
  data: function () {
    return {
      selected: "vca",
      toggle: false,
      files: [],
      selectedFile: null,
    }
  },
  methods: {
    selectFile: function (file) {
      this.selectedFile = file
    },
    resetUploader: function () {
      if (!(this.$refs['object-tracker-uploader'] instanceof Object)) return
      this.$refs['object-tracker-uploader'].reset()
      this.files = []
      this.selectedFile = null
      this.toggle = false
    },
    deleteFileInUploader: function (file) {
      if (!(this.$refs['object-tracker-uploader'] instanceof Object)) return
      this.$refs['object-tracker-uploader'].removeFile(file)
    },
    onClickFile: function (file) {
      this.selectFile(file)
      this.toggle = true
    },
    onDeleteFile: function (file) {
      const index = this.files.indexOf(file)
      if (index < 0) return
      this.files = [ ...this.files.slice(0, index), ...this.files.slice(index + 1) ]
      this.deleteFileInUploader(file)
      if (this.files.length) this.selectedFile = this.files[0]
      else {
        this.selectedFile = null
        this.toggle = false
      }
    },
    onClickClearAll: function () {
      this.$q.dialog({
        title: 'Clear All',
        message: 'Would you like to clear all video files in the list?',
        cancel: true,
        persistent: false
      }).onOk(() => {
        this.resetUploader()
      })
    },
    onFileAdded: function (files) {
      this.files = this.files.concat(files)
      this.selectFile(files[0])
    },
    checkFileType: function (files) {
      const uList = []
      for (let i=0; i < files.length; i++) {
        const file = files[i]
        // CHECK - file type (only mp4 file)
        switch (file.type) {
        case 'video/mp4':
          break
        default:
          this.onFileRejected(file, 'is not mp4 video')
          continue
        }
        // CHECK - file size (less than 10MB)
        if (file.size > 1024*1024*10) {
          this.onFileRejected(file, `is larger than 10MB: ${file.size} bytes`)
          continue
        }
        uList.push(file)
      }
      return uList
    },
    onFileRejected: function (file, reason) {
      this.$q.notify({
        type: 'warning',
        message: `"${file.name}" ${reason}`
      })
    },
  },
}
</script>
